<template>
  <v-footer id="pages-core-footer" absolute v-bind="$attrs">
    <cookie-consent />
    <v-container fluid>
      <v-row align="center" justify="center" no-gutters>
        <v-btn :href="privacyLink" target="_target" min-height="48" text>
          <fa-icon :icon="['fal', 'user-lock']" style="max-width: 15px" class="mr-2" />
          <span>Privacy</span>
        </v-btn>
        <v-spacer class="hidden-sm-and-down" />
        <v-col v-if="!isWhiteLabelled" cols="12" md="auto">
          <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
            &copy; {{ formatDateCustom(new Date(), 'YYYY') }}, made with
            <v-icon color="blue" size="20">mdi-heart</v-icon>
            by
            <a href="https://www.odro.co.uk" target="_blank" class="odro-blue-new text-decoration-none">Odro</a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'PagesCoreFooter',
  components: {
    CookieConsent: () => import('@/components/core/CookieConsent'),
  },
  computed: {
    ...mapState('video', { video: 'current' }),
    ...mapGetters('application', ['footerNav']),
    isWhiteLabelled() {
      return this.video?.user?.client?.metadata?.isWhiteLabelled;
    },
    privacyLink() {
      return (
        this.video?.user?.client?.metadata?.privacyPolicyUrl ||
        'https://www.odro.co.uk/compliance/product-privacy-policy/'
      );
    },
  },
};
</script>
